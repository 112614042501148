import React, { useContext, useEffect, useRef, useState } from 'react'
import './otp.css';
import OtpInput from 'react-otp-input';
import axios from 'axios';
import { toast } from 'react-toastify';
import endPoints from '../constants';
import { Bars } from 'react-loader-spinner';
import { useLocation, useNavigate } from 'react-router-dom';
import { MyContext } from '../hooks/MyProvider';
import SimpleHeader from '../components/SimpleHeader';

const OtpVerification = () => {
  const [otp, setOtp] = useState('');
  const { phone, setPhone } = useContext(MyContext);
  const inputRef = useRef(); // Create a ref object
  console.log("phone", phone);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();
  const request = useLocation();
  const [requestState, setRequestState] = useState(null);
  const user = localStorage.getItem("skyfi");
  const tk = JSON.parse(user);

  useEffect(() => {
    if (request.state) {
      setRequestState(request.state);
    }
  }, [request]);
  const handleOtpChange = (otpValue) => {
    console.log(otpValue)
    if (/^[0-9]*$/.test(otpValue)) {
      setOtp(otpValue);
    } else {
      setOtp(otpValue)
    }
  };
  console.log("requestState", requestState);

  const handleVerify = async (e) => {
    e.preventDefault();

    if (otp.length != 4) {
      toast.error("Invalid Code");
      return;
    }

    let data = {
      request_id: requestState,
      code: otp
    }
    try {
      setLoading(true);
      const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.VERIFY_CODE}`, data, {
        headers: {
          'Authorization': `Bearer ${tk.access_token}`
        }
      });
      setLoading(false);
      if (resp?.status === 200) {
        toast.success("MFA Verified Successfully");
        navigate("/identity-verification", { state: true });
      }

    } catch (error) {
      setLoading(false);
      toast.error(`${error.response.data.error}`);
    }
  };

  const handleResend = async (e) => {
    e.preventDefault();
    const verification = {
      country_code: phone?.countryCode,
      phone_number: phone?.phone
    }
    try {
      setLoading(true);
      const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.MFA_VERIFY}`, verification, {
        headers: {
          'Authorization': `Bearer ${tk.access_token}`
        }
      });
      setLoading(false);
      console.log("resp", resp);
      if (resp?.status === 200) {
        setRequestState(resp?.data?.request_id);
        toast.success("Code resend Successfully");
      }

    } catch (error) {
      setLoading(false);
      toast.error(`${error.response.data.error}`);
    }
  }

  const handlePhoneNumber = async (e) => {
    e.preventDefault();
    if (!phoneNumber) {
      toast.error("Phone number is required");
      inputRef.current.focus();
      return;
    }
    let dataPhone = phoneNumber.replace(/-/g, "");
    console.log("dataPhone", dataPhone.length);

    if (dataPhone && dataPhone?.length != 10) {
      toast.error("Phone number should be 10 digits");
      inputRef.current.focus();
      return;
    }

    const verification = {
      country_code: phone?.countryCode,
      phone_number: dataPhone,
    }
    try {
      setLoading(true);
      const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.MFA_VERIFY}`, verification, {
        headers: {
          'Authorization': `Bearer ${tk.access_token}`
        }
      });
      setLoading(false);
      console.log("resp", resp);
      if (resp?.status === 200) {
        setRequestState(resp?.data?.request_id);
        setPhone({ phone: dataPhone });
        setEdit(false);
        toast.success("Number Update Successfully");
      }

    } catch (error) {
      setLoading(false);
      toast.error(`${error.response.data.error}`);
    }
  }

  const formatPhone = (phone) => {
    if (phone) {
      return phone?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }
    return phone;
  }

  const handleEdit = () => {
    setEdit(!edit);
    setPhoneNumber('');
  }
  useEffect(() => {
    if (edit) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 1000)
    }
  }, [edit])

  return (
    <>
      <SimpleHeader />
      {!loading && (
        <div className="row">
          <div className="otp-box">
            <div className="bg-white p-5 rounded-4 shadow-sm border">
              <div>
                <div className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 1024 1024">
                    <path fill="currentColor" d="M800 384h-32V261.872C768 115.024 661.744 0 510.816 0C359.28 0 256 117.472 256 261.872V384h-32c-70.592 0-128 57.408-128 128v384c0 70.592 57.408 128 128 128h576c70.592 0 128-57.408 128-128V512c0-70.592-57.408-128-128-128zM320 261.872C320 152.784 394.56 64 510.816 64C625.872 64 704 150.912 704 261.872V384H320V261.872zM864.001 896c0 35.28-28.72 64-64 64h-576c-35.28 0-64-28.72-64-64V512c0-35.28 28.72-64 64-64h576c35.28 0 64 28.72 64 64v384zm-352-320c-35.344 0-64 28.656-64 64c0 23.632 12.96 44.032 32 55.12V800c0 17.664 14.336 32 32 32s32-14.336 32-32V695.12c19.04-11.088 32-31.504 32-55.12c0-35.344-28.656-64-64-64z" />
                  </svg>
                </div>
                <h5>Enter Verification Code</h5>
                <p>A verification code has been sent to:</p>
                <div className="otp-phone">
                  {!edit && <span>{formatPhone(phone?.phone)}</span>}
                  {edit && <span><input type="text" ref={inputRef} maxLength={12} value={formatPhone(phoneNumber)} onChange={(e) => setPhoneNumber(e.target.value)} /></span>}
                  {!edit && <button onClick={handleEdit}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 256 256"><path fill="currentColor" d="m227.31 73.37l-44.68-44.69a16 16 0 0 0-22.63 0L36.69 152A15.86 15.86 0 0 0 32 163.31V208a16 16 0 0 0 16 16h44.69a15.86 15.86 0 0 0 11.31-4.69L227.31 96a16 16 0 0 0 0-22.63ZM51.31 160l90.35-90.35l16.68 16.69L68 176.68ZM48 179.31L76.69 208H48Zm48 25.38L79.31 188l90.35-90.35l16.68 16.69Z" /></svg>
                  </button>}
                  {edit && <button onClick={handlePhoneNumber}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24"><path fill="currentColor" d="M9 16.2L4.8 12l-1.4 1.4L9 19L21 7l-1.4-1.4L9 16.2z" /></svg>
                  </button>}
                </div>

                <form className="row">
                  <p>Authentication Code</p>
                  <OtpInput
                    value={otp}
                    onChange={handleOtpChange}
                    numInputs={4}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} className='otp-letter-input' placeholder=' ' />}
                  />
                </form>
                <div className="row button-sec">
                  <div className="col-12">
                    <div className="text-muted text-center">
                      <a href="#" onClick={handleResend}>Resend Code</a>
                    </div>
                    <button className="btn" onClick={handleVerify}>Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Bars
        height="80"
        width="80"
        color="#39AFFF"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass="bars"
        visible={loading}
      />
    </>
  )
}

export default OtpVerification
