import React from 'react'
import Sidebar from './Sidebar'

const Sidedefault = () => {
    const sidebarNav = document?.querySelector('.s-sidebar__nav');
    const mediaQuery = window?.matchMedia('(min-width: 1em)');

    const toggleSidebar = () => {
        if (mediaQuery?.matches) {
            if (sidebarNav.style.width === '20em') {
                sidebarNav.style.width = ''; // Reset to default
            } else {
                sidebarNav.style.width = '20em'; // Expand to 20em
            }
        }
    }
    mediaQuery?.addEventListener('change', () => {
        if (!mediaQuery?.matches) {
            sidebarNav.style.width = ''; // Reset to default width
        }
    });
    return (
        <div className="sidebar-part">
            <Sidebar />
        </div>
    )
}

export default Sidedefault
