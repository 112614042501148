import React, { useEffect, useState } from "react";
import MainHeader from "../components/MainHeader";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import endPoints from "../constants";
import Select from "react-select";
import { toast } from "react-toastify";
import axios from "axios";
import { Bars } from "react-loader-spinner";
import { usePlaidLink } from "react-plaid-link";
import { Modal } from "react-responsive-modal";
import Sidebar from "../components/Sidebar";
import Sidedefault from "../components/Sidedefault";

const RelinkExternalAccount = () => {

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (!location?.state) {
      navigate("/external-account");
    }
  }, [location?.state])
  const params = useParams();
  const [filteredData, setFilteredData] = useState([]);
  const [options, setOptions] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [plaidToken, setPlaidToken] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [tokenLoading, setToknLoading] = useState(false);
  const { data: allAccountsData, dataLoading: allAccountsDataLoading } =
    useFetch(`${endPoints.ALL_ACCOUNTS}`);
  const [openModal, setOpenModal] = useState(false);

  const onOpenModal = () => setOpenModal(true);
  const onCloseModal = () => setOpenModal(false);
  const filterAcnts = () => {
    let linkedAccountIds =
      location?.state?.attributes?.linked_account_ids || [];

    let filtered = allAccountsData?.data
      ?.filter((item) => {
        // return linkedAccountIds.includes(Number(item?.id));
        return linkedAccountIds.includes(item?.attributes?.uuid);
      })
      ?.map((item) => {
        return {
          value: item?.attributes?.uuid,
          label: item?.attributes?.label,
        };
      });

    let allOptions = allAccountsData?.data?.map((item) => {
      return { value: item?.attributes?.uuid, label: item?.attributes?.label };
    });

    setOptions(allOptions);
    setFilteredData(filtered);
  };

  useEffect(() => {
    if (allAccountsData) {
      filterAcnts();
    }
  }, [allAccountsData]);

  const handleChange = (selectedOptions) => {
    setFilteredData(selectedOptions);
  };

  // console.log("filteredData", filteredData);
  // console.log("options", options);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSubmitLoading(true);
      let acc_ids = filteredData?.map((item) => {
        return item.value;
      });

      const user = localStorage.getItem("skyfi");
      const tk = JSON.parse(user);
      const resp = await axios.put(
        `${process.env.REACT_APP_BASE_URL}${endPoints.ACH_PAYMENTS}/${location?.state?.attributes?.uuid}/update_linked_accounts`,
        { account_id: acc_ids },
        {
          headers: {
            Authorization: `Bearer ${tk.access_token}`,
          },
        }
      );

      setSubmitLoading(false);
      if (resp.status === 201) {
        toast.success("Link Account Updated Successfully ");
        navigate("/external-account");
      }
    } catch (error) {
      console.log(error);
      setSubmitLoading(false);
      toast.error(error?.response?.data?.error);
      toast.error(error?.response?.data?.error[0]?.errorDesc);
    }
  };

  // handle relink
  const get_client_access_token = async () => {
    try {
      setToknLoading(true);
      setPlaidToken(null);

      let data = {
        // android: false,
        relink: true,
        account_number: location?.state?.attributes?.account_number,
        routing_number: location?.state?.attributes?.routing_number,
        account_type: location?.state?.attributes?.account_type,
        bank_name: location?.state?.attributes?.bank_name,
      };
      const user = localStorage.getItem("skyfi");
      const tk = JSON.parse(user);
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${endPoints.PLAID_TOKEN_REQUEST}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${tk.access_token}`,
          },
        }
      );
      setToknLoading(false);
      if (resp.status == 200) {
        setPlaidToken(resp?.data);
      }
      // console.log(resp);
    } catch (error) {
      setToknLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.error);
      toast.error(error?.response?.data?.error[0]?.errorDesc);
    }
  };
  // plaid auth
  const GetPlaidAuthDetails = async (token, accountId) => {
    try {
      const user = localStorage.getItem("skyfi");
      const tk = JSON.parse(user);
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${endPoints.PLAID_AUTH}`,
        {
          // "public_token": token,
          // "plaid_account_id": accountId,
          // "payment_type": "external"
          public_token: token,
          plaid_account_id: accountId,
          payment_type: "external",
          relink: true,
          // android: false,
          account_number: location?.state?.attributes?.account_number,
          routing_number: location?.state?.attributes?.routing_number,
          account_type: location?.state?.attributes?.account_type,
          bank_name: location?.state?.attributes?.bank_name,
        },
        {
          headers: {
            Authorization: `Bearer ${tk.access_token}`,
          },
        }
      );
      if (resp.status == 200) {
        // setAccData((prev) => ({
        //     ...prev,
        //     "account_number": resp?.data?.auth?.numbers?.ach?.[0]?.account,
        //     "routing_number": resp?.data?.auth?.numbers?.ach?.[0]?.routing,
        //     "account_type": resp?.data?.auth?.accounts?.[0]?.subtype,
        //     "plaid_account_id": accountId,
        //     "plaid_access_token": resp?.data?.access_token,
        // }))
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.error);
      toast.error(error?.response?.data?.error[0]?.errorDesc);
    }
  };
  const { open, ready } = usePlaidLink({
    token: plaidToken?.link_token, // pass the dynamic token prop here
    onSuccess: (public_token, metadata) => {
      // send public_token to server

      GetPlaidAuthDetails(public_token, metadata?.account_id);
      setPlaidToken(null);

      navigate("/external-account");
    },
    onExit: (data) => {
      navigate("/external-account");
    },
  });
  useEffect(() => {
    if (plaidToken?.link_token) {
      open(); // call open() when the token is available and the link is ready
    }
  }, [plaidToken?.link_token, ready]);
  // loading bars
  const loading = () => {
    if (allAccountsDataLoading) {
      return true;
    } else return false;
  };
  // handle delete
  const handleDelete = async () => {
    try {
      setDeleteLoading(true);
      const user = localStorage.getItem("skyfi");
      const tk = JSON.parse(user);
      const resp = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/${endPoints.ACH_PAYMENTS}/${params?.acc_id?.split("_")[0]
        }`,
        {
          headers: {
            Authorization: `Bearer ${tk.access_token}`,
          },
        }
      );
      if (resp?.status == 200) {
        toast.success("External account deleted successfully");
        onCloseModal();
        navigate("/external-account");
      }
      setDeleteLoading(false);
    } catch (error) {
      setDeleteLoading(false);
      toast.error(error?.response?.data?.error);
      toast.error(error?.response?.data?.error[0]?.errorDesc);
      console.log(error);
    }
  };

  return (
    <>
      {/* <MainHeader /> */}
      {!loading() && (
        <div className="s-layout">
          <Sidedefault />
          <main className="s-layout__content">
            <div className="main-page">
              <div className="row justify-content-center h-100 align-items-center">
                <div className="col-lg-8 col-md-10">
                  <div className="card login-part">
                    <div className="authincation-content">
                      <div className="row no-gutters">
                        <div className="col-xl-12">
                          <div className="auth-form">
                            <div className="d-flex justify-content-between">
                              <h4 className="mb-4 "><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 20 20">
              <path fill="currentColor" d="M9.993 10.573a4.5 4.5 0 1 0 0-9a4.5 4.5 0 0 0 0 9ZM10 0a6 6 0 0 1 3.04 11.174c3.688 1.11 6.458 4.218 6.955 8.078c.047.367-.226.7-.61.745c-.383.045-.733-.215-.78-.582c-.54-4.19-4.169-7.345-8.57-7.345c-4.425 0-8.101 3.161-8.64 7.345c-.047.367-.397.627-.78.582c-.384-.045-.657-.378-.61-.745c.496-3.844 3.281-6.948 6.975-8.068A6 6 0 0 1 10 0Z" />
            </svg> External Account</h4>
                              <div className="text-center">
                                <button
                                  type="submit"
                                  className="btn"
                                  onClick={get_client_access_token}
                                  disabled={tokenLoading}
                                >
                                  {tokenLoading
                                    ? "Loading"
                                    : "Relink Account"}
                                </button>
                              </div>
                            </div>
                            <form className="mt-4" onSubmit={handleSubmit}>
                              <div className="form-group">
                                <label className="mb-1 form-label">
                                  Populates for
                                </label>
                                <div className="form-group">
                                  <Select
                                    options={options}
                                    value={filteredData}
                                    // className="form-control"
                                    isMulti
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="text-center d-flex justify-content-between">
                                <button
                                  type="submit"
                                  className="btn"
                                  disabled={submitLoading}
                                >
                                  {submitLoading
                                    ? "Transferring..."
                                    : "Submit"}
                                </button>
                                <button
                                  type="button"
                                  className="btn"
                                  onClick={onOpenModal}
                                  disabled={deleteLoading}
                                >
                                  {deleteLoading ? "Deleting..." : "Delete"}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      )}
      <Modal open={openModal} onClose={onCloseModal} center>
        <div className="p-5">
          <div className="">
            <h4 className="mb-4 ">
              Are you sure you want to delete this account?
            </h4>
          </div>
          <div className="d-flex  justify-content-center gap-3 ">
            <button
              className="whit-btn"
              onClick={handleDelete}
              disabled={deleteLoading}
            >
              {deleteLoading ? "Deleting..." : "Sure"}
            </button>
            <button className="blk-btn" onClick={onCloseModal}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <Bars
        height="80"
        width="80"
        color="#39AFFF"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass="bars"
        visible={loading()}
      />
    </>
  );
};

export default RelinkExternalAccount;
