import React, { useEffect, useState } from 'react';
import MainHeader from '../components/MainHeader';
import useFetch from '../hooks/useFetch';
import endPoints from '../constants';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import { usePlaidLink } from 'react-plaid-link';
import { Bars } from 'react-loader-spinner';
import Sidebar from '../components/Sidebar';
import Sidedefault from '../components/Sidedefault';

const ACHPayOut = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const [accountDetails, setAccountDetails] = useState(null)
    const [accNum, setAccNum] = useState(null);
    const [errors, setErrors] = useState({});
    const [submitLoading, setSubmitLoading] = useState(false)
    const [plaidToken, setPlaidToken] = useState("");

    const [achData, setAchData] = useState({
        "purpose": "",
        "amount": "",
        "account_number": ""
    });
    const { data, dataLoading } = useFetch(`${endPoints.ACH_PAYMENTS}?type=external%2Cincoming&account_id=${params?.acc_label?.split("_")[0]}`);
    //    set inital achdata values
    const setAccDetails = async (raw) => {

        const detail = data?.data?.filter((item) => {
            if (item?.attributes?.account_number === raw) {
                return item
            }
        });


        if (detail) {
            setAccountDetails(detail[0]?.attributes);
        }

    }
    // onchange function
    const handleChange = (name, value) => {
        // let { name, value } = e.target;


        setAchData({ ...achData, [name]: value });
        if (errors[name]) {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
        }
    }
    // alert(((accountDetails?.plaid_account_id ?? achData?.plaid_account_id) , (accountDetails?.plaid_access_token ?? achData?.plaid_access_token)))
    // handle submit 
    const handleSubmit = async (e, plaiAccountId, plaidAccessToken) => {

        e?.preventDefault();
        let validationErrors = {};

        if (!plaiAccountId || !plaidAccessToken) {
            if (!accountDetails?.plaid_account_id || !accountDetails?.plaid_access_token) {
                const achd = {

                    account_type: accountDetails?.account_type,
                    account_number: accountDetails?.account_number,
                    routing_number: accountDetails?.routing_number,
                    account_id: location?.state?.uuid,
                };
                return await getPlaidToken(achd);
            }
        }

        if (!accNum) validationErrors.account_number = "Account Number is required";
        if (!achData.amount) validationErrors.amount = "Amount is required";
        if (!achData.purpose) validationErrors.purpose = "Description is required";


        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        try {
            setSubmitLoading(true);

            let resultData = {
                ...achData,
                "sent_from": accountDetails?.label,
                "label": accountDetails?.label,
                "bank_name": accountDetails?.bank_name,
                "account_type": accountDetails?.account_type,
                "type": "incoming",
                "plaid_account_id": plaiAccountId ?? accountDetails?.plaid_account_id,
                "plaid_access_token": plaidAccessToken ?? accountDetails?.plaid_access_token,
                "account_number": accountDetails?.account_number,
                "routing_number": accountDetails?.routing_number,
                "purpose": achData?.purpose,
                "amount": achData?.amount,
                "account_id": location?.state?.uuid,
                "payment_id": accountDetails?.uuid,
            }

            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.NEW_ACH_INCOMING}`, resultData,
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );

            setSubmitLoading(false);
            if (resp.status === 200) {
                toast.success("Transaction Successful ");
                // navigate("/view-account", { state: location?.state });
                navigate(`/${params?.name}/accounts/${params?.acc_label}`);
            }
        } catch (error) {
            setSubmitLoading(false);
            console.log(error);
            toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.error[0]?.errorDesc);
        }
    }
    const getPlaidToken = async (data) => {
        try {

            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.PLAID_TOKEN_REQUEST}`, data,
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );
            if (resp?.status == 200) {
                setPlaidToken(resp?.data);
                setAchData({
                    ...achData,
                    "plaid_account_id": resp?.data?.request_id,
                    "plaid_access_token": resp?.data?.link_token,

                });
            }
        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.error[0]?.errorDesc);
        }
    }


    const GetPlaidAuthDetails = async (token, accountId) => {
        try {
            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.PLAID_AUTH}`,
                {
                    // "public_token": token,
                    // "plaid_account_id": accountId,
                    // "payment_type": "external"
                    "public_token": token,
                    "plaid_account_id": accountId,
                    "payment_type": "external",
                    "relink": true,
                    "account_number": accountDetails?.account_number,
                    "routing_number": accountDetails?.routing_number,
                    "bank_name": accountDetails?.bank_name,
                    "account_type": accountDetails?.account_type,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );
            if (resp.status == 200) {

                handleSubmit(null, token, accountId)

                // setAccData((prev) => ({
                //     ...prev,
                //     "account_number": resp?.data?.auth?.numbers?.ach?.[0]?.account,
                //     "routing_number": resp?.data?.auth?.numbers?.ach?.[0]?.routing,
                //     "account_type": resp?.data?.auth?.accounts?.[0]?.subtype,
                //     "plaid_account_id": accountId,
                //     "plaid_access_token": resp?.data?.access_token,

                // }))


            }

        } catch (error) {
            console.log(error)
            toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.error[0]?.errorDesc);
        }
    }
    const { open, ready } = usePlaidLink({
        token: plaidToken?.link_token, // pass the dynamic token prop here
        onSuccess: (public_token, metadata) => {
            // send public_token to server

            GetPlaidAuthDetails(public_token, metadata?.account_id)
            // setPlaidToken(null)

        },
        onExit: (data) => {

            navigate("/external-account");
        }
    });
    useEffect(() => {
        if (plaidToken?.link_token) {

            open();
        }
    }, [plaidToken?.link_token, ready]);
    return (
        <>
            {/* <MainHeader /> */}
            {!dataLoading &&
                <div className="s-layout">
                    <Sidedefault />
                    <main className="s-layout__content">
                        <div className="main-page">
                            <div className="row ">
                                <div className="col-lg-8 col-md-10">
                                    <div className="card login-part">
                                        <div className="authincation-content">
                                            <div className="row no-gutters">
                                                <div className="col-xl-12">
                                                    <div className="auth-form">
                                                        <h4 className="mb-4"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512">
    <path fill="currentColor" d="M247.759 14.358L16 125.946V184h480v-58.362ZM464 152H48v-5.946l200.241-96.412L464 146.362ZM48 408h416v32H48zm-32 56h480v32H16zm40-248h32v160H56zm368 0h32v160h-32zm-96 0h32v160h-32zm-176 0h32v160h-32zm88 0h32v160h-32z"/>
</svg> ACH Payment</h4>
                                                        <form onSubmit={handleSubmit} className="mt-4">

                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">Account Number</label>
                                                                <div className="input-group">
                                                                    <select
                                                                        name='account_number'

                                                                        onChange={(e) => { handleChange(e.target.name, e.target.value); setAccDetails(e.target.value); setAccNum(e.target.value) }}

                                                                        className={`form-control`}
                                                                        aria-label="Default select example"
                                                                    >
                                                                        <option selected="">Select Account</option>
                                                                        {data?.data?.length && data?.data?.map((item, id) => {
                                                                            return (
                                                                                <option key={id} value={item?.attributes?.account_number}>{item?.attributes?.label}</option>
                                                                            )
                                                                        })}

                                                                    </select>

                                                                </div>
                                                                {errors.account_number && <div className="text-danger">{errors.account_number}</div>}
                                                            </div>



                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">Amount</label>
                                                                {/* <input
                                                                    onChange={handleChange}
                                                                    value={achData?.amount}
                                                                    name='amount'
                                                                    type="text"
                                                                    className={`form-control `}
                                                                    placeholder="Amount"
                                                                /> */}
                                                                <CurrencyInput
                                                                    className="form-control"
                                                                    name="amount"
                                                                    prefix="$"
                                                                    placeholder="Amount"
                                                                    defaultValue={achData?.amount}
                                                                    decimalsLimit={2}
                                                                    onValueChange={(value, name, values) => {
                                                                        handleChange(name, value);
                                                                        // setdetails(prev => ({ ...prev, amount: value }))

                                                                    }}
                                                                />
                                                                {errors.amount && <div className="text-danger">{errors.amount}</div>}

                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">Description</label>
                                                                <textarea
                                                                    name='purpose'
                                                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                                                    value={achData?.purpose}
                                                                    type="text"
                                                                    className={`form-control `}
                                                                    placeholder="Description"
                                                                    defaultValue={""}
                                                                />
                                                                {errors.purpose && <div className="text-danger">{errors.purpose}</div>}

                                                            </div>
                                                            <div className="text-center">
                                                                <button type="submit" className="btn" disabled={submitLoading}>
                                                                    {submitLoading ? "Transferring..." : "Submit"}
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            }
            <Bars
                height="80"
                width="80"
                color="#39AFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={dataLoading}
            />
        </>
    );
}

export default ACHPayOut;
