import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import Dashboard from '../pages/Dashboard'
import Footer from './Footer'
import Accounts from '../pages/Accounts'
import AccountDetail from '../pages/AccountDetail'
import AddingAccountBusiness from '../pages/AddingAccountBusiness'
import AddingAccount from '../pages/AddingAccount'
import ViewAccount from '../pages/ViewAccount'
// import { CreateBusiness } from '../pages/CreateBusiness'
// import BusinessAddress from '../pages/BusinessAddress'
import BusinessForm from '../pages/BusinessForm'
import Cards from '../pages/Cards'
import AddCard from '../pages/AddCard'
import FundThisAccount from '../pages/FundThisAccount'
import CardType from '../pages/CardType'
import CardSection from '../pages/CardSection'
import CreateCard from '../pages/CreateCard'
import CardDetails from '../pages/CardDetails'
import SetPin from '../pages/SetPin'
import BillingAddress from '../pages/BillingAddress'
// import SetCardLimit from '../pages/SetCardLimit'
import CardTransaction from '../pages/CardTransaction'
import ViewAllContacts from '../pages/ViewAllContacts'
import AddContact from '../pages/AddContact'
import ContactAddress from '../pages/ContactAddress'
import SpendingPatterns from '../pages/SpendingPatterns'
import ContactDetails from '../pages/ContactDetails'
import UpdateContact from '../pages/UpdateContact'
import UpdateContactAddress from '../pages/UpdateContactAddress'
import StaffList from '../pages/StaffList'
import AddStaff from '../pages/AddStaff'
import EditStaff from '../pages/EditStaff'
import Settings from '../pages/Settings'
import ExternalAccount from '../pages/ExternalAccount'
import MoveFunds from '../pages/MoveFunds'
import ContactTransferMoney from '../pages/ContactTransferMoney'
import ACHPayOut from '../pages/ACHPayOut'
import IntraPayOut from '../pages/IntraPayOut'
import LinkExternalAccount from '../pages/LinkExternalAccount'
import ACHPayIn from '../pages/ACHPayIn'
import MoveFundsToAnotherBank from '../pages/MoveFundsToAnotherBank'
import FundTransaction from '../pages/FundTransaction'
import RelinkExternalAccount from '../pages/RelinkExternalAccount'
import AccountDetailsLinkExternalAccount from '../pages/AccountDetailsLinkExternalAccount'
import PageNotFount from '../pages/PageNotFount'
import { decryptData } from '../hooks/encryptionUtils'
import ProtectedRoute from './ProtectedRoute'
import { AuthProvider } from './AuthContext'
import { IntercomProvider, useIntercom } from "react-use-intercom";
import Cookies from 'js-cookie';
import CertificateOfDeposit from '../pages/CertificateOfDeposit'
import MultiStepForm from '../pages/MultiStepForm'
import ACHOrigination from '../pages/ACHOrigination'

const Layout = () => {
  
  const INTERCOM_APP_ID = "n3syma1m";
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(null);
  const handleDecrypt = () => {
    let user_role = localStorage.getItem("skyrole");

    if (user_role) {
      try {
        const decrypted = decryptData(user_role);
        setUserRole(decrypted);

      } catch (error) {
        console.error('Decryption error:', error.message);
        localStorage.clear();
        navigate("/login");
      }
    }
  };
  useEffect(() => {
    handleDecrypt();
    Cookies.set('url',window.location.pathname)
    localStorage.setItem("url", window.location.pathname)
  }, [window.location.pathname])
  // if (!userRole) {
  //   // localStorage.clear();
  //   // navigate("/login");
  //   return
  // }

  return (
    <>
      <AuthProvider>
        <IntercomProvider
          appId={INTERCOM_APP_ID}
          autoBoot
        >
          <Routes>
            {/* <Route path='/' element={<Dashboard />} /> */}
           
            <Route path='/' element={<ProtectedRoute roles={['admin', 'edit_account', 'view_account', "user", "card_only"]} element={<Dashboard />} />} />
            <Route path='/certificate-of-deposit' element={<ProtectedRoute roles={['admin', 'edit_account', 'view_account', "user", "card_only"]} element={<CertificateOfDeposit />} />} />
            <Route path={`/:name/accounts`} element={<ProtectedRoute roles={['admin', 'edit_account', 'view_account', "user"]} element={<Accounts />} />} />
            <Route path='/accounts' element={<ProtectedRoute roles={['admin', 'edit_account', 'view_account', "user"]} element={<Accounts />} />} />
            <Route path='/:name/accounts/:acc_label/account-details' element={<ProtectedRoute roles={['admin', 'view_account', "edit_account", "user"]} element={<AccountDetail />} />} />
            {/* <Route path='/adding-account-business' element={<ProtectedRoute roles={["user"]} element={<AddingAccountBusiness />} />} /> */}
            <Route path='/:b_name/ach-origination' element={<ProtectedRoute roles={["user"]} element={<ACHOrigination />} />} />
            <Route path='/:b_name/adding-account' element={<ProtectedRoute roles={["user"]} element={<AddingAccount />} />} />
            <Route path='/:name/accounts/:acc_label' element={<ProtectedRoute roles={['admin', 'view_account', "edit_account", "user"]} element={<ViewAccount />} />} />
            {/* <Route path='/create-business' element={<ProtectedRoute roles={["user"]} element={<CreateBusiness />} />} /> */}
            <Route path='/create-business' element={<ProtectedRoute roles={["user"]} element={<BusinessForm />} />} />
            {/* <Route path='/update-business/:b_id' element={<ProtectedRoute roles={["user", "admin"]} element={<CreateBusiness />} />} /> */}
            <Route path='/update-business/:b_id' element={<ProtectedRoute roles={["user", "admin"]} element={<BusinessForm />} />} />
            {/* <Route path='/business-address' element={<ProtectedRoute roles={["user"]} element={<BusinessAddress />} />} /> */}
            {/* <Route path='/update-business-address/:b_id' element={<ProtectedRoute roles={["user", "admin"]} element={<BusinessAddress />} />} /> */}
            <Route path='/:name/accounts/:acc_label/cards' element={<ProtectedRoute roles={['admin', 'card_only', "edit_account", 'view_account', "user"]} element={<Cards />} />} />
            <Route path='/:name/accounts/:acc_label/cards/card-type' element={<ProtectedRoute roles={['admin', 'card_only', "edit_account", "user"]} element={<CardType />} />} />
            <Route path='/:name/accounts/:acc_label/cards/add-card/:card_name' element={<ProtectedRoute roles={['admin', 'card_only', "edit_account", "user"]} element={<AddCard />} />} />
            <Route path='/:name/accounts/:acc_label/cards/add-card/:card_name/:card_type/create-card' element={<ProtectedRoute roles={['admin', 'card_only', "edit_account", "user"]} element={<CreateCard />} />} />
            <Route path='/:name/accounts/:acc_label/cards/card-section/:card_id' element={<ProtectedRoute roles={['admin', 'card_only', "edit_account", 'view_account', "user"]} element={<CardSection />} />} />
            <Route path='/card-detail' element={<ProtectedRoute roles={['admin', 'card_only', "edit_account", 'view_account', "user"]} element={<CardDetails />} />} />
            <Route path='/set-pin' element={<ProtectedRoute roles={['admin', 'card_only', "edit_account", 'view_account', "user"]} element={<SetPin />} />} />
            <Route path='/:name/accounts/:acc_label/cards/card-section/billing-address' element={<ProtectedRoute roles={['admin', 'card_only', "edit_account", 'view_account', "user"]} element={<BillingAddress />} />} />
            {/* <Route path='/card-llimit' element={<ProtectedRoute roles={['admin', 'card_only', "edit_account", "user"]} element={<SetCardLimit />} />} /> */}
            <Route path='/card-transaction/:acc_id' element={<ProtectedRoute roles={['admin', 'card_only', "edit_account", 'view_account', "user"]} element={<CardTransaction />} />} />
            <Route path='/card-transaction/:acc_id/:id' element={<ProtectedRoute roles={['admin', 'card_only', "edit_account", 'view_account', "user"]} element={<CardTransaction />} />} />
            <Route path='/view-contacts/:acc_id' element={<ProtectedRoute roles={['admin', "edit_account", 'view_account', "user"]} element={<ViewAllContacts />} />} />
            <Route path='/:name/accounts/:acc_label/fund-this-account' element={<ProtectedRoute roles={['admin', "edit_account", "user"]} element={<FundThisAccount />} />} />
            <Route path='/add-contact/:acc_id' element={<ProtectedRoute roles={['admin', "edit_account", "user"]} element={<AddContact />} />} />
            <Route path='/contact-address/:acc_id' element={<ProtectedRoute roles={['admin', "edit_account", 'view_account', "user"]} element={<ContactAddress />} />} />
            <Route path='/contact-details/:acc_id/:contact_id' element={<ProtectedRoute roles={['admin', "edit_account", 'view_account', "user"]} element={<ContactDetails />} />} />
            <Route path='/update-contact/:acc_id/:contact_id' element={<ProtectedRoute roles={['admin', "edit_account", "user"]} element={<UpdateContact />} />} />
            <Route path='/update-contact-address/:acc_id/:contact_id' element={<ProtectedRoute roles={['admin', "edit_account", "user"]} element={<UpdateContactAddress />} />} />
            <Route path='/spending-patterns/:acc_id' element={<ProtectedRoute roles={['admin', "edit_account", 'view_account', "user"]} element={<SpendingPatterns />} />} />
            <Route path='/staff-list' element={<ProtectedRoute roles={['admin', "user"]} element={<StaffList />} />} />
            <Route path='/add-staff' element={<ProtectedRoute roles={['admin', "user"]} element={<AddStaff />} />} />
            <Route path='/edit-staff/:staff_id' element={<ProtectedRoute roles={['admin', "user"]} element={<EditStaff />} />} />
            <Route path='/settings' element={<ProtectedRoute roles={['admin', "edit_account", 'view_account', "user"]} element={<Settings />} />} />
            <Route path='/external-account' element={<ProtectedRoute roles={['admin', "edit_account", "user"]} element={<ExternalAccount />} />} />
            <Route path='/move-funds/:b_id/:acc_id' element={<ProtectedRoute roles={['admin', "edit_account", "user"]} element={<MoveFunds />} />} />
            <Route path='/contact-transfer-money/:acc_id/:contact_id' element={<ProtectedRoute roles={['admin', "edit_account", "user"]} element={<ContactTransferMoney />} />} />
            <Route path='/ach-pay-out/:acc_id/:contact_id' element={<ProtectedRoute roles={['admin', "edit_account", "user"]} element={<ACHPayOut />} />} />
            <Route path='/intra-pay-out/:b_id/:acc_id' element={<ProtectedRoute roles={['admin', "edit_account", "user"]} element={<IntraPayOut />} />} />
            <Route path='/link-external-account' element={<ProtectedRoute roles={['admin', "edit_account", "user"]} element={<LinkExternalAccount />} />} />
            <Route path='/:name/:acc_label/ach-pay-in' element={<ProtectedRoute roles={['admin', "edit_account", "user"]} element={<ACHPayIn />} />} />
            <Route path='/move-fund-to-another-account/:acc_id' element={<ProtectedRoute roles={['admin', "edit_account", "user"]} element={<MoveFundsToAnotherBank />} />} />
            <Route path='/move-fund-to-another-account/:acc_id/fund-transaction/:label_id' element={<ProtectedRoute roles={['admin', "edit_account", "user"]} element={<FundTransaction />} />} />
            <Route path='/relink-external-account/:acc_id' element={<ProtectedRoute roles={['admin', "edit_account", "user"]} element={<RelinkExternalAccount />} />} />
            <Route path='/link-external-account-detail/:acc_id' element={<ProtectedRoute roles={['admin', "edit_account", "user"]} element={<AccountDetailsLinkExternalAccount />} />} />
            <Route path='/register/rollfi/:b_id' element={<ProtectedRoute roles={['admin', "edit_account", "user"]} element={<MultiStepForm />} />} />
            <Route path='/*' element={<PageNotFount />} />
          </Routes>
          <Footer />
        </IntercomProvider>
      </AuthProvider>
    </>
  )
}

export default Layout