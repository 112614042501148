import React from 'react'
import MainHeader from '../components/MainHeader'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Sidebar from '../components/Sidebar';
import Sidedefault from '../components/Sidedefault';

const ContactTransferMoney = () => {
    const navigate=useNavigate();
    const location=useLocation();
    const params = useParams();
  return (
    <>
    {/* <MainHeader /> */}
          <div className="s-layout">
              <Sidedefault />
              <main className="s-layout__content">
                  <div className="main-page">
                          <div className="row">
                              <div className="col-xl-12">
                                  <div className="card plan-list">
                                      <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                                          <div className="me-auto pe-3 mb-3">
                                              <h4 className="text-black fs-26 mb-3"><svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24"><path fill="currentColor" d="M19.44 5.78H4.56a2.507 2.507 0 0 0-2.5 2.5v7.44a2.514 2.514 0 0 0 2.5 2.5h14.88a2.507 2.507 0 0 0 2.5-2.5V8.28a2.5 2.5 0 0 0-2.5-2.5ZM3.06 8.28a1.5 1.5 0 0 1 1.5-1.5h1.48a3.521 3.521 0 0 1-2.98 2.98Zm1.5 8.94a1.511 1.511 0 0 1-1.5-1.5v-1.48a3.521 3.521 0 0 1 2.98 2.98Zm16.38-1.5a1.5 1.5 0 0 1-1.5 1.5h-1.48a3.521 3.521 0 0 1 2.98-2.98Zm0-2.49a4.528 4.528 0 0 0-3.99 3.99h-9.9a4.528 4.528 0 0 0-3.99-3.99v-2.46a4.528 4.528 0 0 0 3.99-3.99h9.9a4.528 4.528 0 0 0 3.99 3.99Zm0-3.47a3.521 3.521 0 0 1-2.98-2.98h1.48a1.5 1.5 0 0 1 1.5 1.5Z"/><circle cx="12.002" cy="11.998" r="2" fill="currentColor"/></svg> Transfer Money</h4>
                                          </div>
                                      </div>
                                      <div className="card-body  pt-2">
                                          <div className="row">
                                              <div className="col-xl-12">
                                                  <h5>Send Money Via</h5>
                                                  <ul className="tab-links">
                                                      <li>
                                                          <a className="cursor-pointer" onClick={() => { navigate(`/intra-pay-out/${params?.acc_id?.split("_")[0]}_acc/${params?.contact_id}_cont`,{state:location?.state}) }}>
                                                              Intrabank
                                                              <svg
                                                                  width={19}
                                                                  height={19}
                                                                  viewBox="0 0 19 19"
                                                                  fill="none"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                              >
                                                                  <path
                                                                      d="M17.832 9.49976H1.16537"
                                                                      stroke="black"
                                                                      strokeWidth="1.375"
                                                                      strokeMiterlimit={10}
                                                                      strokeLinecap="round"
                                                                  />
                                                                  <path
                                                                      d="M10.5061 1.52051L17.353 8.3653C17.5029 8.51352 17.6219 8.69001 17.7031 8.88455C17.7843 9.07909 17.8262 9.28781 17.8262 9.49863C17.8262 9.70945 17.7843 9.91817 17.7031 10.1127C17.6219 10.3073 17.5029 10.4837 17.353 10.632L10.5061 17.4788"
                                                                      stroke="black"
                                                                      strokeWidth="1.375"
                                                                      strokeLinecap="round"
                                                                      strokeLinejoin="round"
                                                                  />
                                                              </svg>
                                                          </a>
                                                      </li>
                                                      <li>
                                                          <a className="cursor-pointer" onClick={() => { navigate(`/ach-pay-out/${params?.acc_id?.split("_")[0]}_acc/${params?.contact_id}_cont`, { state: location?.state })}}>
                                                              ACH{" "}
                                                              <svg
                                                                  width={19}
                                                                  height={19}
                                                                  viewBox="0 0 19 19"
                                                                  fill="none"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                              >
                                                                  <path
                                                                      d="M17.832 9.49976H1.16537"
                                                                      stroke="black"
                                                                      strokeWidth="1.375"
                                                                      strokeMiterlimit={10}
                                                                      strokeLinecap="round"
                                                                  />
                                                                  <path
                                                                      d="M10.5061 1.52051L17.353 8.3653C17.5029 8.51352 17.6219 8.69001 17.7031 8.88455C17.7843 9.07909 17.8262 9.28781 17.8262 9.49863C17.8262 9.70945 17.7843 9.91817 17.7031 10.1127C17.6219 10.3073 17.5029 10.4837 17.353 10.632L10.5061 17.4788"
                                                                      stroke="black"
                                                                      strokeWidth="1.375"
                                                                      strokeLinecap="round"
                                                                      strokeLinejoin="round"
                                                                  />
                                                              </svg>
                                                          </a>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      </main>
          </div>

    </>
  )
}

export default ContactTransferMoney