import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import Cookies from 'js-cookie';
import { useAuth } from "./AuthContext";
import logo from "../assets/images/logo.png"
import photo from "../assets/images/no-user.svg"
import { decryptData } from "../hooks/encryptionUtils";
import endPoints from "../constants";
import axios from "axios";
import SardineService from "../hooks/SardineService";

const Sidebar = () => {
  const { userRole } = useAuth();
  const [activeTab, setActiveTab] = useState("/");
  const navigate = useNavigate();
  const { show, shutdown } = useIntercom();

  // sardine logout
  function handleSardineLogout() {
    // After logout, reset userIdHash and sessionKey
    SardineService.updateConfig({
      userIdHash: null,
      sessionKey: "NEW_SESSION_KEY", // Generate new session key
      flow: "/login" // Example flow after logout
    });
  };

  const handleSignOut = () => {
    localStorage.clear();
    Cookies.remove('url');
    shutdown();
    handleSardineLogout()
    navigate("/login", { replace: true });

  };
  useEffect(() => {
    console.log("routes are changing on", window.location.pathname);
    setActiveTab(window.location.pathname)
  }, [window.location.pathname])


  const [fixedSidebar, setFixedSidebar] = useState(false);
  let [userDetails, setUserDetails] = useState(null)
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 150) {
        setFixedSidebar(true);
      } else {
        setFixedSidebar(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const user = localStorage.getItem("skyfi");
  const tk = JSON.parse(user);
  const getUserDetails = async () => {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}${endPoints.ME}`,
      {
        headers: {
          'Authorization': `Bearer ${tk.access_token}`
        }
      }
    );
    if (resp.status == 200) {
      // setUserDetails(resp?.data)
      let user = JSON.stringify(resp?.data)
      localStorage.setItem("user", user)
      // toast.success("Business Created");

    }
  }

  const userd = localStorage.getItem("user");
  useEffect(() => {
    if (userd) {
      try {
        const decrypted = decryptData(userd);
        if (tk && !decrypted) getUserDetails();
        if (decrypted) {
          setUserDetails(decrypted)
        }
        // setUserRole(decrypted);
      } catch (error) {
        console.error('Decryption error:', error.message);
        localStorage.clear();
        navigate("/login");
      }
    }
  }, [userd])

  const handleInterCom = () => {
    setActiveTab("main")
    show();
  }

  useEffect(() => {
    setActiveTab("main")
  }, [handleInterCom])

  return (
    <>    
<input id="slide-sidebar" type="checkbox" role="button" />
        <label for="slide-sidebar"><span><i className="fa fa-bars"></i></span></label>

    <div className="sidebar">
      <div>
      <div className="dashboard_bar cursor-pointer" onClick={() => { navigate("/") }}>
        <img src={logo} />
      </div>
      <ul
        className="nav"
        id="menu"
       
      >
        {userRole != "edit_account" && userRole != "view_account" && userRole != "card_only" &&

          <li className="nav-item">
            <NavLink to="/staff-list" className={`nav-link align-middle px-0`} onClick={() => setActiveTab("/staff-list")}>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 256 256">
                <path fill="currentColor" d="M117.25 157.92a60 60 0 1 0-66.5 0a95.83 95.83 0 0 0-47.22 37.71a8 8 0 1 0 13.4 8.74a80 80 0 0 1 134.14 0a8 8 0 0 0 13.4-8.74a95.83 95.83 0 0 0-47.22-37.71ZM40 108a44 44 0 1 1 44 44a44.05 44.05 0 0 1-44-44Zm210.14 98.7a8 8 0 0 1-11.07-2.33A79.83 79.83 0 0 0 172 168a8 8 0 0 1 0-16a44 44 0 1 0-16.34-84.87a8 8 0 1 1-5.94-14.85a60 60 0 0 1 55.53 105.64a95.83 95.83 0 0 1 47.22 37.71a8 8 0 0 1-2.33 11.07Z" />
              </svg>
              <span className="ms-1  d-sm-inline">Staff</span>
            </NavLink>
          </li>}
        <li>
          <Link
            to="#submenu1"
            data-bs-toggle="collapse"
            className={`nav-link align-middle px-0`} onClick={() => setActiveTab("#submenu1")}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 20 20">
              <path fill="currentColor" d="M9.993 10.573a4.5 4.5 0 1 0 0-9a4.5 4.5 0 0 0 0 9ZM10 0a6 6 0 0 1 3.04 11.174c3.688 1.11 6.458 4.218 6.955 8.078c.047.367-.226.7-.61.745c-.383.045-.733-.215-.78-.582c-.54-4.19-4.169-7.345-8.57-7.345c-4.425 0-8.101 3.161-8.64 7.345c-.047.367-.397.627-.78.582c-.384-.045-.657-.378-.61-.745c.496-3.844 3.281-6.948 6.975-8.068A6 6 0 0 1 10 0Z" />
            </svg>
            <span className="ms-1  d-sm-inline">
              Accounts <i className="fa fa-angle-down" aria-hidden="true"></i>
            </span>
          </Link>
          <div
            className="collapse nav ms-1 drop-menus"
            id="submenu1"
            data-bs-parent="#menu"
          >
            <ul>
              {userRole != "edit_account" && userRole != "view_account" && userRole != "card_only" &&

                <li>
                  <NavLink to="/external-account" className={`nav-link align-middle px-0`} onClick={() => setActiveTab("/external-account")}>
                    <span className="">External Accounts</span>
                  </NavLink>
                </li>}
              <li>
                <NavLink to={'/accounts'} className={`nav-link align-middle px-0`} onClick={() => setActiveTab("/accounts")}>

                  <span className="">Business Accounts</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <Link href="#" className={`nav-link align-middle px-0`} onClick={handleInterCom}>
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 14 14">
              <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M3 7V4.37A3.93 3.93 0 0 1 7 .5a3.93 3.93 0 0 1 4 3.87V7M1.5 5.5h1A.5.5 0 0 1 3 6v3a.5.5 0 0 1-.5.5h-1a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1m11 4h-1A.5.5 0 0 1 11 9V6a.5.5 0 0 1 .5-.5h1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1M9 12.25a2 2 0 0 0 2-2V8m-2 4.25a1.25 1.25 0 0 1-1.25 1.25h-1.5a1.25 1.25 0 0 1 0-2.5h1.5A1.25 1.25 0 0 1 9 12.25" />
            </svg>
            <span className="ms-1  d-sm-inline"> Get Help</span>
          </Link>
        </li>
        <li>
          <NavLink
            to="/" className={`nav-link align-middle px-0`} onClick={() => setActiveTab("/")}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 14 14">
              <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
                <path d="m9.008 6.709l3.43-1.516v7.672h-3.43zM4.72 4.814l2.144 3.032l2.144-1.137v6.154H4.72z" />
                <path d="m1.29 6.33l3.43-1.516v8.05H1.29zm12-5.193L7.851 4.613l-2.5-2.923L.878 3.988" />
                <path d="m10.586.678l2.703.45l-.45 2.704" />
              </g>
            </svg>
            <span className="ms-1  d-sm-inline"> Businesses</span>
          </NavLink>
        </li>
        <li>
          <Link
            to="https://skyfichecking.com/disclosures/"
            target="_blank"
            className="nav-link px-0 align-middle"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24">
              <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                <path d="M14.186 2.753v3.596c0 .487.195.955.54 1.3a1.85 1.85 0 0 0 1.306.539h4.125" />
                <path d="M20.25 8.568v8.568a4.251 4.251 0 0 1-1.362 2.97a4.283 4.283 0 0 1-3.072 1.14h-7.59a4.294 4.294 0 0 1-3.1-1.124a4.265 4.265 0 0 1-1.376-2.986V6.862a4.25 4.25 0 0 1 1.362-2.97a4.283 4.283 0 0 1 3.072-1.14h5.714a3.503 3.503 0 0 1 2.361.905l2.96 2.722a2.971 2.971 0 0 1 1.031 2.189M7.647 7.647h3.265M7.647 12h8.706m-8.706 4.353h8.706" />
              </g>
            </svg>
            <span className="ms-1  d-sm-inline"> Disclosures</span>
          </Link>
        </li>
        {/* <li>
          <a className="cursor-pointer nav-link px-0 align-middle" onClick={handleSignOut}>
            <i className="fs-4 bi-people" />
            <span className="ms-1  d-sm-inline"> Sign Out</span>
          </a>
        </li> */}
      </ul>
      </div>
      <div className="side-botom">
        <div className="usr">
          <a>
            <img src={photo} alt='user' />
            <span>{userDetails?.person?.first_name} {userDetails?.person?.last_name}</span>
          </a>
        </div>
        <a className="cursor-pointer nav-link px-3 align-middle" onClick={handleSignOut}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 14"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M9.5 10.5v2a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1v-11a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v2M6.5 7h7m-2-2l2 2l-2 2" /></svg>
          <span className="ms-1  d-sm-inline"> Sign Out</span>
        </a>
      </div>

    </div>
    </>
  );
};

export default Sidebar;
