import React, { useEffect, useState } from 'react'
import MainHeader from '../components/MainHeader'
import { Link, useNavigate } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import endPoints from '../constants';
import { Bars } from 'react-loader-spinner';
import Sidebar from '../components/Sidebar';
import Sidedefault from '../components/Sidedefault';
import profile from '../assets/images/no-user.svg';

const StaffList = () => {
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [filterStaff, setFilterStaff] = useState([]);
    const { data, dataLoading } = useFetch(endPoints.STAFFS);
    let staff_slug = (item) => {
        let slug = item?.uuid + "_" + (item?.first_name + "-" + item?.last_name).toLowerCase()?.replaceAll(" ", "-")
        return slug
    }

    const handleChange = (e) => {
        setSearch(e.target.value)
        console.log("data?.data", data?.data);

        let fitr = data?.data?.filter((item) => {
            console.log("item", item);

            if (item?.attributes?.first_name?.toLowerCase()?.includes(e.target.value.toLowerCase()) || item?.attributes?.last_name?.toLowerCase()?.includes(e.target.value.toLowerCase())) {
                return item
            }
        })
        setFilterStaff(fitr)
    }

    useEffect(() => {
        if (data) {
            let ft = [...data?.data]
            setFilterStaff(ft)
        }
    }, [data])

    const shortName = (name) => {
        name = name?.split(" ");
        // console.log("name");
        let res = name[0][0]?.toUpperCase()
        // let res = name?.length > 1 ? name[0][0]?.toUpperCase() + name[1][0]?.toUpperCase() : name[0][0]?.toUpperCase()
        return res
    }
    return (
        <>
            {/* <MainHeader /> */}
            {!dataLoading &&
                <div className="s-layout">
                    {/* Sidebar */}
                    <Sidedefault />
                    {/* Content */}
                    <main className="s-layout__content">
                        <div className="main-page">
                            <div className="row">
                                <div className="card plan-list">
                                    <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                                        <div className="me-auto pe-3 mb-3">
                                            <h4 className="text-black fs-26 mb-3"><svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24">
                                                <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                                                    <path d="M15.854 2.75H9.687A5.139 5.139 0 0 0 4.55 7.889v8.222c0 2.838 2.3 5.139 5.138 5.139h6.167c2.838 0 5.139-2.3 5.139-5.139V7.89c0-2.838-2.3-5.139-5.139-5.139M3.007 8.403H6.09m-3.083 7.194H6.09" />
                                                    <path d="M16.67 16.745c0-1.653-1.843-2.996-3.495-2.996c-1.653 0-3.495 1.343-3.495 2.995m3.495-5.376a2.348 2.348 0 1 0 0-4.696a2.348 2.348 0 0 0 0 4.696" />
                                                </g>
                                            </svg> Staff </h4>
                                        </div>

                                    </div>
                                    <div className="card-body  pt-2">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="search-contact-row ads-staff">
                                                    <div className="search-contact">
                                                        <input
                                                            value={search}
                                                            onChange={handleChange}
                                                            type="type"
                                                            className="form-control"
                                                            placeholder="Search Contacts"
                                                        />
                                                        <button type="submit" className="">
                                                            Search
                                                        </button>
                                                    </div>
                                                    <div className="add-staff">
                                                        <Link to="/add-staff">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={20}
                                                                height={20}
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <path
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    strokeLinecap="square"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth={2}
                                                                    d="M12 6v12m6-6H6"
                                                                />
                                                            </svg>
                                                            Add
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 mt-5">
                                                {/* <h5>Staff</h5> */}
                                                <ul className="tab-links stf-list">
                                                    {filterStaff?.length > 0 && filterStaff?.map((item) => {
                                                        return (

                                                            <li key={item?.id} onClick={() => { navigate(`/edit-staff/${staff_slug(item?.attributes)}`, { state: { item } }) }} className='cursor-pointer'>
                                                                <Link >
                                                                    <span>
                                                                        {/* <img src={profile} alt='staff' /> */}
                                                                        <div className="list-icons">{shortName(item?.attributes?.first_name)}</div>
                                                                        {item?.attributes?.first_name} {item?.attributes?.last_name}
                                                                    </span>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512">
                                                                        <path fill="currentColor" d="m359.873 121.377l-22.627 22.627l95.997 95.997H16v32.001h417.24l-95.994 95.994l22.627 22.627L494.498 256L359.873 121.377z" />
                                                                    </svg>
                                                                </Link>
                                                            </li>
                                                        )
                                                    })}

                                                    {filterStaff?.length == 0 && <p className="">No Data Found</p>}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main >
                </div >}
            <Bars
                height="80"
                width="80"
                color="#39AFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={dataLoading}
            />
        </>
    )
}

export default StaffList