import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Sidedefault from '../components/Sidedefault';
import { toast } from 'react-toastify';
import { Bars } from 'react-loader-spinner';

const ACHOrigination = () => {
    const [content, setContent] = useState('');
    const [checked, setChecked] = useState(false);

    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();

    const handleSubmit = () => {
        if (!checked) {
            toast.error("Please accept terms and conditions");
            return;
        }
        const busines_id = location?.state?.businessId;
        navigate(
            `/${params?.b_name}/adding-account`,
            {
                state: {
                    businessId: busines_id,
                    businessData: location?.state?.businessData,
                    flag: true
                },
            }
        );
    }


    useEffect(() => {
        fetch(process.env.REACT_APP_BUSINESS_CHECKING_ACCOUNT_AGREEMENT_URL)
            .then((response) =>
                response.text(),

            ) // Use .text() to get raw HTML
            .then((data) => setContent(data),
            )
            .catch((error) => console.error('Error fetching content:', error));
    }, []);


    return (
        <>
            {content ? (
                <div className="s-layout">
                    {/* Sidebar */}
                    <Sidedefault />
                    <main className="s-layout__content">
                        <div className="main-page">
                            <div className="row">
                                <div className="card plan-list">
                                    <div className="card-header bg-white d-sm-flex flex-wrap d-block px-5 pb-0 border-0">
                                        <div className="me-auto mt-4 pe-3 mb-3">
                                            <h4 className="text-black fs-26 mb-0 d-flex">
                                                <svg width="30" height="33" viewBox="0 0 52 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="1" y="1" width="50" height="63" rx="4" stroke="#06284E" strokeWidth="2" />
                                                    <rect x="7" y="55" width="30" height="3" rx="1.5" fill="#06284E" />
                                                    <rect x="7" y="45" width="30" height="3" rx="1.5" fill="#06284E" />
                                                    <rect x="7" y="35" width="30" height="3" rx="1.5" fill="#06284E" />
                                                    <rect x="7" y="25" width="30" height="3" rx="1.5" fill="#06284E" />
                                                    <rect x="8.5" y="9.5" width="37" height="7" rx="3.5" stroke="#06284E" strokeWidth="3" />
                                                </svg>
                                                ACH Origination
                                            </h4>
                                        </div>
                                    </div>
                                    <div className='ach-orig-box'>
                                        <div
                                            dangerouslySetInnerHTML={{ __html: content }}
                                        />
                                        <div className="terms">
                                            <input type="checkbox" id="termsCheckbox" onChange={(e) => setChecked(e.target.checked)} />
                                            <label for="termsCheckbox">Check the box to agree to all policies, agreements, and terms & conditions</label>
                                        </div>
                                        <button className="proced-terms m-3 " onClick={handleSubmit}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            ) :


                <Bars
                    height="80"
                    width="80"
                    color="#39AFFF"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass="bars"
                    visible={content ? false : true}
                />

            }
        </>
    )
}

export default ACHOrigination
